<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">{{planName}}</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="m-title">{{ infoData.name }}</div>
      <div class="l-item">
        <el-image class="elimage" :src="icon1" />
        <div class="title">实习形式：{{ infoData.type | typeformat }}</div>
      </div>
      <div class="l-item">
        <el-image class="elimage" :src="image1" />
        <div class="title">
          实习时间：{{ infoData.start_date | dataformat}}
          ~ {{infoData.end_date | dataformat}}
        </div>
      </div>
      <div class="l-item">
        <el-image class="elimage" :src="image2" />
        <div class="title">
          要求提交时间：{{infoData.report_commit_start_date | dataformat}}
          ~ {{infoData.report_commit_end_date | dataformat}}
        </div>
      </div>
      <div class="l-line"></div>
      <el-form
        class="elform"
        ref="ruleForm"
        :model="formData"
        :rules="formRules"
        label-width="100px"
      >
        <el-form-item class="elinput" prop="title" label="标题:">
          <el-input v-model="formData.title" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item class="elinput" prop="intro" label="实习纪要:">
          <el-input
            type="textarea"
            v-model="formData.intro"
            :autosize="{ minRows: 3, maxRows: 6 }"
            maxlength="100"
            show-word-limit
            style="color:#606266"
          ></el-input>
        </el-form-item>
        <el-form-item class="elinput" prop="content" label="实习报告:">
          <div class="text-editor">
            <Tinymce
              ref="editor"
              v-model="formData.content"
              style="height: auto; border-radius: 22px"
              @setInput="getValue"
            ></Tinymce>
          </div>
        </el-form-item>
        <el-form-item class="elinput" label="附件:">
          <UploadFiles
            :files="fileList"
            :module="'practice,report/url'"
            :cloudType="'PracticeReport/url'"
            @setUrl="getUrl"
          />
          <!-- <div class="t_text">{{formData.file}}</div> -->
        </el-form-item>
      </el-form>
      <div class="bts">
        <el-button type="info" @click="cancelbt">取消</el-button>
        <el-button
          type="primary"
          :loading="saveLoad"
          @click="submitbt('ruleForm',0)"
          style="background:#3d84ff"
        >保存</el-button>
        <el-button
          type="primary"
          :loading="submitLoad"
          @click="submitbt('ruleForm',1)"
          style="background:#3d84ff"
        >提交</el-button>
        <!-- <div class="bt" @click="cancelbt"></div> -->
        <!-- <div class="bt bt1" @click="submitbt(0)"></div>
        <div class="bt bt1" @click=""></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { addpracticereport, getpracticereportinfo } from "@/api/practicereport"
import Tinymce from "@/components/VueTinymce/index.vue"
import UploadFiles from "@/components/upload-files.vue" // 文件上传
import dayjs from "dayjs"

const typemap = ["自主报名", "集中安排"]

export default {
  name: "submitreport",
  components: {
    Tinymce,
    UploadFiles
  },
  filters: {
    typeformat (val) {
      return typemap[val]
    },
    dataformat (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  data () {
    return {
      infoData: {},
      icon1: require("@/assets/coursedetails/数据 (1).png"),
      image1: require("@/assets/coursedetails/center/时间 钟表.png"),
      image2: require("@/assets/coursedetails/center/提交1.png"),
      formData: {
        id: '',
        title: "",
        intro: "",
        content: "",
        file: "",
      },
      formRules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' },
          { min: 0, max: 20, message: '长度在20个字符', trigger: 'blur' }
        ],
        intro: [
          { required: true, message: '实习纪要不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '实习内容不能为空', trigger: 'blur' },
        ]
      },
      fileList: [],
      saveLoad: false,
      submitLoad: false,
    }
  },
  computed: {
    planName () {
      return this.$route.query.plan_name
    },
    status () {
      return this.$route.query.status
    },
    practice_plan_id () {
      return this.$route.query.practice_plan_id
    },
    practice_report_id () {
      return this.$route.query.practice_report_id
    },
    student_id () {
      return this.$route.query.student_id
    }
  },
  created () {
    this.initInfo()
  },
  methods: {
    initInfo () {
      const params = {
        id: this.practice_report_id,
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id
      }
      getpracticereportinfo(params).then(res => {
        if (res.code === 0) {
          this.infoData = res.data.plan_info
          const data = res.data.report_info
          this.formData = {
            id: data.id,
            title: data.title,
            intro: data.intro,
            content: data.content,
            file: data.file[0],
          }
          if (data.file && Array.isArray(data.file)) {
            this.fileList = data.file
            this.formData.file = JSON.stringify(data.file)
          } else {
            this.fileList = []
            this.formData.file = ''
          }
        }
      })
    },
    toback () {
      this.$router.replace({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      })
    },
    cancelbt () {
      this.toback()
    },
    submitbt (formName, action) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (action) {
            this.submitLoad = true
          } else {
            this.saveLoad = true
          }
          let params = {
            id: this.formData.id,
            is_submit: action,
            practice_plan_id: this.practice_plan_id,
            title: this.formData.title,
            intro: this.formData.intro,
            content: this.formData.content,
            file: this.formData.file,
          }
          addpracticereport(params).then((response) => {
            if (response.code === 0) {
              if (action == 1) {
                this.submitLoad = false
                this.$message.success("实习报告提交成功!")
              } else {
                this.saveLoad = false
                this.$message.success("实习报告保存成功!")
              }
              this.toback()
            } else {
              if (action) {
                this.submitLoad = false
              } else {
                this.saveLoad = false
              }
            }
          }).catch(() => {
            if (action) {
              this.submitLoad = false
            } else {
              this.saveLoad = false
            }
          })
        }
      })
    },
    // 获取富文本得值
    getValue (data) {
      //console.log(data)
      this.formData.content = data
    },
    getUrl (data, key) {
      this.formData.file = data
    }
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  background: #fff;
  min-height: calc(100vh - 240px);
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    padding-top: 10px;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .line {
    margin-top: 10px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    .m-title {
      margin-bottom: 40px;
      font-size: 25px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .l-item {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .title {
        margin-left: 10px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .l-line {
      margin-top: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #b3b3b3;
    }
    .elform {
      .elinput {
        width: 800px;
      }
      .file-select-contain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .file-selecttip {
          margin-left: 10px;
          padding-left: 10px;
          text-align: left;
          width: 200px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #d8d8d8;
          border-radius: 4px;
        }
        .file-selectbt {
          margin-left: 10px;
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          width: 121px;
          height: 40px;
          line-height: 40px;
          padding: 0px;
          text-align: center;
          background: #0773fc;
          border: 1px solid #0773fc;
          border-radius: 4px;
          //  border: 1px solid red;
          &:active {
            background: #0773fc;
          }
        }
      }
    }
    .bts {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .bt {
        margin-right: 10px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #a0a0a0;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        background: #3d84ff;
        color: #ffffff;
      }
    }
  }
}
</style>
